._wrap-footer {
  background-color: $color-white;

  @include media-breakpoint-up(xs) {
    padding-bottom: 3rem;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }

  ._footer {
    ._med-container {
      border-top: 1px solid $color-ice;
      
      @include media-breakpoint-up(xs) {
        padding: {
          top: 0.9375rem;
          bottom: 1.875rem;
        }
      }

      @include media-breakpoint-up(md) {
        padding: {
          top: 1.875rem;
          bottom: 1.875rem;
        }
      }

      .cols {
        display: flex;

        @include media-breakpoint-up(xs) {
          align-items: center;
          flex-direction: column;
        }

        @include media-breakpoint-up(lg) {
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: row;
        }

        ._col {
          width: 240px;

          @include media-breakpoint-up(xs) {
            text-align: center;

            &::after {
              display: block;
              content: "";
              height: 1px;
              width: 100%;
              background-color: $color-ice;

              @include media-breakpoint-up(xs) {
                margin: {
                  top: 0.875rem;
                  bottom: 0.875rem;
                }
              }

              @include media-breakpoint-up(lg) {
                margin: {
                  top: 1.875rem;
                  bottom: 1.875rem;
                }
              }
            }

            &:last-child {
              &::after {
                display: none;
              }
            }
          }

          @include media-breakpoint-up(lg) {
            text-align: left;

            &::after {
              display: none;
            }
          }

          .inside-col {
            .title {
              margin-bottom: 1.275rem;
            }

            ._list-nav._first-list {
              li {
                @include media-breakpoint-up(xs) {
                  display: none;
                }

                @include media-breakpoint-up(lg) {
                  display: block;
                }

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(5) {
                  display: block;
                }

                &:nth-child(2),
                &:nth-child(5) {
                  display: block;

                  @include media-breakpoint-up(xs) {
                    border-top: 1px solid $color-ice;
                    padding-top: 10px;
                  }

                  @include media-breakpoint-up(md) {
                    border-top: 0;
                    padding-top: 0;
                  }
                }
              }
            }

            ._list-nav {
              ul {
                li {
                  a {
                    color: $color-black;

                    @include media-breakpoint-up(xs) {
                      justify-content: center;
                    }

                    @include media-breakpoint-up(lg) {
                      justify-content: flex-start;
                    }
                  }
                }
              }
            }

            ._list-nav.socials {
              margin-bottom: 1.875rem;

              ul {
                display: flex;
                align-items: center;

                @include media-breakpoint-up(xs) {
                  justify-content: center;
                }

                @include media-breakpoint-up(lg) {
                  justify-content: flex-start;
                }

                li {
                  margin: 0;
                  margin-left: 10px;

                  &:first-child {
                    margin-left: 0;
                  }

                  a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $color-blue;
                    width: 36px;
                    height: 36px;
                    min-width: 36px;
                    min-height: 36px;
                    border-radius: 100px;

                    svg {
                      width: 16px;
                      height: 16px;

                      path {
                        fill: $color-white;
                      }
                    }
                  }
                }
              }
            }

            .secure-logo {
              img {
                height: 38px;
                width: auto;
              }
            }
          }
        }

        ._col._col-1 {
          .inside-col {
            text-align: center;
            .logo {
              display: inline-block;
              margin-bottom: 0.7rem;

              @include media-breakpoint-up(xs) {
                margin-top: 15px;
              }

              @include media-breakpoint-up(md) {
                margin-top: 25px;
              }

              img {
                width: 34px;
              }
            }

            p {
              text-align: center;
              color: #465065;
              font-size: 10px;
              opacity: 0.8;
            }
          }
        }

        ._col._col-2._institucional {
          .inside-col {
            ._list-nav {
              ul {
                li {
                  @include media-breakpoint-up(xs) {
                    display: none;
                  }

                  @include media-breakpoint-up(lg) {
                    display: block;
                  }

                  &:nth-child(1) {
                    display: block;
                  }
                }
              }
            }
          }
        }

        ._col._col-2._second-column {
          .inside-col {
            ._list-nav {
              ul {
                li {
                  &:nth-child(4) {
                    display: block;
                    
                    @include media-breakpoint-up(xs) {
                      border-top: 1px solid #f6f6f9;
                      padding-top: 10px;
                    }
                    
                    @include media-breakpoint-up(md) {
                      border-top: 0;
                      padding-top: 0;
                    }
                  }
                }
              }
            }
          }
        }

        ._col._col-2,
        ._col._col-2._institucional {
          .inside-col {
            .title {
              @include media-breakpoint-up(xs) {
                display: none;
              }

              @include media-breakpoint-up(lg) {
                display: block;
              }
            }
          }
        }

        ._col._col-3 {
          @include media-breakpoint-up(xs) {
            display: none;
          }

          @include media-breakpoint-up(lg) {
            display: block;
          }
        }

        ._col._col-4 {
          ._legals {
            @include media-breakpoint-up(xs) {
              display: none;
            }

            @include media-breakpoint-up(lg) {
              display: block;
            }
          }
        }

        ._col._col-4 {
          .inside-col {
            .title {
              @include media-breakpoint-up(xs) {
                font-size: 12px;
                text-transform: lowercase;
              }

              @include media-breakpoint-up(lg) {
                font-size: 14px;
                text-transform: initial;
              }
            }
            span {
              a {
                color: $color-black;
              }

              &:last-child {
                margin-top: 10px;
              }
            }
          }
        }

        ._col._col-1,
        ._col._col-2,
        ._col._col-3,
        ._col._col-4 {

          .inside-col {

            .title {
              font-family: $font-oswald !important;
              text-transform: uppercase;
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  .copyrights {
    ._med-container {
      border-top: 1px solid $color-ice;
      padding: {
        top: 0.9375rem;
        bottom: 0.9375rem;
      }

      p {
        @include media-breakpoint-up(xs) {
          text-align: center;
        }

        @include media-breakpoint-up(lg) {
          text-align: left;
        }

        &:first-child {
          margin-bottom: 4px;
        }
      }
    }
  }
}
