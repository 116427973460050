#my-purchases {
  ._wrap-title-and-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-ice;

    @include media-breakpoint-up(xs) {
      flex-direction: column;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    ._title-box {
      @include media-breakpoint-up(xs) {
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }
    ._material-tabs {
      @include media-breakpoint-up(xs) {
        width: $percent-100;
        border: {
          top: 1px solid $color-ice-bold;
          bottom: 1px solid $color-ice-bold;
        }
      }

      @include media-breakpoint-up(md) {
        border: 0;
      }
    }

    ._title-box {
      margin-bottom: 0;
    }
  }

  ._accordion {

    ._accordion-content {
      margin-bottom: 6px;

      ul._list-content {
        li {
          ._wrap-user {
            display: flex;
            align-items: center;

            ._username {
              font-size: 13px;
              font-weight: $fw700;
              color: $color-gray-bolder;
              margin-left: 8px;
            }
          }
        }

        li.flex {
          ._item-content {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            ._frete {
              font-size: 12px;
              position: relative;
              top: -2px;
              margin-left: 6px;

              svg {
                width: 18px;
                height: auto;
                position: relative;
                top: -1px;
                margin-right: 4px;

                path {
                  fill: $color-black;
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }
    }
    
  }

  ._accordion {

    ._accordion-head.shopping,
    ._accordion-head.scamb {
      border: 1px solid $color-ice;
    }
  }

  ._accordion._subitems {
    margin-bottom: 6px;
  }
  

  #simple-tabpanel-1 {
    margin: 0;

    ._accordion._subitems {

      ._accordion-head {

        ._visible-content {

          ._product-thumbnail {

            ._col {

              &:last-child {

                ._product-info {

                  .print-label {

                    li {
                      list-style: none;
                      padding: 0;

                      ._item-title {
                        font-size: 0.625rem;
                        opacity: 0.9;
                        margin-bottom: 0.125rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } 
  }
}
