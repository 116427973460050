#view_my-account-profile--header {
  ._hero {
    max-width: $med-container;
    @include push--auto;
    
    @include media-breakpoint-up(xs) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
    }

    @include media-breakpoint-up(lg) {
      @include padding-lt-rt;
    }

    ._profile-cover {
      width: 100%;
      overflow: hidden;
      background-position: left center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      border-bottom: 1px solid #fbfbfb !important;

      img._img_profile-cover {
        position: absolute;
        width: auto;
        min-height: 106%;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 0;
        height: 100%;
      }

      @include media-breakpoint-up(xs) {
        height: 180px;
      }

      @include media-breakpoint-up(md) {
        height: 260px;
      }

      label {
        position: absolute;
        top: 15px;
        right: 15px;
        max-width: none;
        width: auto;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-white;
        border-radius: 5px;
        border: 1px solid $color-ice;
        height: 24px;
        width: 24px;
        min-height: 24px;

        &:hover {
          border-color: $color-ice-bold;
        }

        svg {
          height: 14px;
          width: auto;

          path {
            fill: $color-blue;
            opacity: 0.7;
          }
        }
      }
    }

    ._profile-bottom {
      @include padding-lt-rt;
      background-color: $color-white;
      display: flex;
      justify-content: space-between;
      border: {
        top: 0;
        left: 1px solid $color-ice;
        right: 1px solid $color-ice;
      }
      min-height: 100px;
      height: auto;

      @include media-breakpoint-up(xs) {
        align-items: flex-start;
        border-bottom: 1px solid $color-ice;

        @include media-breakpoint-up(xs) {
          padding-bottom: 0.9375rem;
        }

        @include media-breakpoint-up(md) {
          padding-bottom: 0;
        }
      }

      @include media-breakpoint-up(md) {
        align-items: center;
      }

      ._wrap-user {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        position: relative;

        @include media-breakpoint-up(xs) {
          top: -45px;
        }

        @include media-breakpoint-up(md) {
          top: -50px;
        }

        ._wrap-user-image {
          position: relative;
          max-width: 68px;
          width: 100%;
          overflow: hidden;

          @include media-breakpoint-up(xs) {
            max-width: 56px;
          }

          @include media-breakpoint-up(md) {
            max-width: 68px;
          }

          .user-image._3x {
            cursor: pointer;
            @include media-breakpoint-up(xs) {
              width: 56px;
              height: 56px;
              min-width: 56px;
              min-height: 56px;
            }

            @include media-breakpoint-up(md) {
              width: 68px;
              height: 68px;
              min-width: 68px;
              min-height: 68px;
            }

            img {
              @include media-breakpoint-up(xs) {
                width: 56px;
                height: 56px;
                min-width: 56px;
                min-height: 56px;
              }
              @include media-breakpoint-up(md) {
                width: 68px;
                height: 68px;
                min-width: 68px;
                min-height: 68px;
              }
            }
          }

          label._label-icon {
            position: absolute;
            bottom: 2px;
            right: 2px;
            max-width: none;
            width: auto;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            border-radius: 100px;
            border: 1px solid $color-ice;
            height: 24px;
            width: 24px;
            min-height: 24px;

            &:hover {
              border-color: $color-ice-bold;
            }

            svg {
              height: 14px;
              width: auto;

              path {
                fill: $color-blue;
                opacity: 0.7;
              }
            }
            
          }
        }

        ._user-info {
          margin-left: 10px;
          position: relative;
          top: 45px;

          ._user-name {
            font-weight: $fw700;
            color: $color-black;
            text-align: left;

            @include media-breakpoint-up(xs) {
              font-size: 14px;
            }

            @include media-breakpoint-up(md) {
              font-size: 16px;
            }

            ._user-location {
              display: block;
              font-size: 11px;

              @include media-breakpoint-up(xs) {
                font-size: 10px;
              }

              @include media-breakpoint-up(md) {
                font-size: 11px;
              }
            }
          }

          ._user-bio {
            font-size: 0.695rem;
            font-weight: $fw700;
            color: $color-gray-bolder;
            margin: 4px 0 8px 0;
            max-width: 580px;
            width: $percent-100;
          }
        }
      }

      ._profile-button-follow {
        @include media-breakpoint-up(xs) {
          margin-left: 10px;
          margin-top: 15px;
        }

        @include media-breakpoint-up(sm) {
          margin-left: 88px;
          margin-top: 0;
        }

        .btn-follow {
          background-color: $color-blue;
          color: $color-white;
          font-size: 10px;
          padding: 0.219rem 0.889rem;
          max-width: 62px;
        }
      }
    }
  }
}

#view_my-account-menu {
  ._menu {
    background-color: $color-white;
    border: 1px solid $color-ice-bold;
    max-width: 290px;
    min-width: 290px;
    width: 100%;
    padding: 15px;

    ._list-nav {
      ul {
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;

          a {
            width: 90%;
          }

          .chevron-right {
            width: 8px;
            height: auto;

            path {
              fill: $color-black;
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

#view_my-account-layout {
  padding-bottom: 30px;
  background-color: #f9fafe;

  ._wrap-content {
    max-width: $med-container;
    @include push--auto;

    @include media-breakpoint-up(md) {
      @include padding-lt-rt;
    }

    ._cols {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      ._col._col-left {
        margin-right: 15px;

        @include media-breakpoint-up(xs) {
          display: none;
        }

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }

      ._col._col-right {
        width: 100%;

        @include media-breakpoint-up(xs) {
          max-width: none;
        }

        @include media-breakpoint-up(lg) {
          max-width: 770px;
        }

        ._wrap-banner-fixed {
          background-color: $color-white;
          text-align: right;

          button._close-banner {
            max-width: none;
            width: auto;
            margin: 0 auto;
            margin-top: 5px;

            @include media-breakpoint-up(xs) {
              display: inline-block;
            }

            @include media-breakpoint-up(md) {
              display: none;
            }
          }

          ._banner-fixed {
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            align-items: center;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 15px;
            margin-bottom: 10px;

            @include media-breakpoint-up(xs) {
              height: auto;
              flex-direction: column;
              justify-content: center;
              //   padding: 15px 0;
            }

            @include media-breakpoint-up(md) {
              height: 64px;
              flex-direction: row;
              justify-content: flex-end;
              padding: 0 15px;
            }

            ._texts-banner {
              @include media-breakpoint-up(xs) {
                text-align: center;
                margin-bottom: 10px;
              }

              @include media-breakpoint-up(md) {
                text-align: right;
                margin-bottom: 0;
              }

              ._the-title {
                color: $color-blue;
                opacity: 0.8;
                @include media-breakpoint-up(xs) {
                  font-size: 14px;
                }
                @include media-breakpoint-up(md) {
                  font-size: 16px;
                }
              }

              ._subtitle {
                font-size: 12px;
                position: relative;
                display: block;
                color: $color-nude;

                @include media-breakpoint-up(xs) {
                  margin-top: 5px;
                }

                @include media-breakpoint-up(md) {
                  left: 15px;
                  margin-top: 0;
                }
              }
            }

            ._invite-label {
              min-width: 340px;

              @include media-breakpoint-up(xs) {
                min-width: 260px;
              }

              @include media-breakpoint-up(md) {
                min-width: 290px;
                margin-left: 35px;
              }

              .form-group {
                display: flex;
                margin-bottom: 0;

                .form-control {
                  font-size: 13px;
                  font-weight: $fw400;
                  color: $color-gray;
                  border: 1px solid #eaeaea;
                  border-right: 0 !important;
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  background-color: $color-white;
                  border-color: #1e3050;
                  padding-left: 15px;

                  &:focus,
                  &:active {
                    outline: 0 !important;
                    border-color: #1e3050 !important;
                  }

                  &:disabled {
                    border: 1px solid #eaeaea;
                    background-color: $color-white !important;
                  }
                }

                .form-control[readonly] {
                  background-color: $color-blue !important;
                }

                .btn {
                  max-width: none;
                  width: auto;
                  border: 1px solid #eaeaea;
                  border-left: 0px;
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                  background-color: $color-white;

                  svg {
                    width: 15px;
                    height: auto;

                    path {
                      fill: $color-gray;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

._title-box {
  padding: 0;
  height: 50px;
  width: $percent-100;
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  margin-bottom: 10px;

  span {
    display: inline-block;
    margin-right: 10px;

    svg {
      height: 16px;
      width: auto !important;

      path {
        fill: $color-blue;
      }
    }
  }


  .title-and-subtitle {
    .title {
      font-size: 14px;
      font-weight: $fw700;
      color: $color-blue;
    }

    .subtitle {
      font-size: 0.6875rem;
      font-weight: $fw700;
      color: $color-blue;
    }
  }
}
