#favorites {
	
	
	._wrap-favorites {
		background-color: $color-white;
		
		._favorites-wrap-list {

			._favorites-list {

				ul {

					li {
						display: flex;
						align-items: center;
						border-top: 1px solid $color-ice;
						padding: 15px 15px;
						overflow: hidden;
						position: relative;

						.product {

							display: flex;
							align-items: center;
							justify-content: flex-start;
							width: $percent-100;

							.product-image {
								height: 80px;
								width: 80px;
								min-width: 80px;
								overflow: hidden;
								border: 1px solid $color-ice;
								border-radius: 5px;

								img {
									width: 100%;
									height: auto;
								}
							}

							.product-info {

								margin-left: 15px;

								.product-info-title {
									font-weight: $fw700;
									color: $color-black;
									
									@include media-breakpoint-up(xs) {
										font-size: 13px;
									}

									@include media-breakpoint-up(md) {
										font-size: 14px;
									}
								}

								.__tag {
									background-color: $color-white;
									border-radius: 0.3125rem;
									padding-left: 14px;
									padding-right: 14px;
									font-size: 12px;
									padding: 0.2rem 0.75rem;
									font-weight: $fw700;
									color: $color-white;
									text-align: left;
									width: auto;
									padding-left: 0;
								}
								
								.__tag.shopping {
									color: $color-violet;
								}
								
								.__tag.scamb {
									color: $color-blue;
								}

							}

						}

						._actions {

							button {
								width: auto;
								padding: 0.5rem 0.8rem;
								background: $color-white;
								line-height: 0;
								position: relative;
					  
								svg {
								  width: auto;
								  height: 18px;
								  -webkit-transition: all 0.15s ease-in-out;
								  -moz-transition: all 0.15s ease-in-out;
								  -ms-transition: all 0.15s ease-in-out;
								  -o-transition: all 0.15s ease-in-out;
								  transition: all 0.15s ease-in-out;
					  
								  path {
									fill: $color-red;
              						opacity: 0.7;
								  }
								}
					  
								.check {
								  background-color: $color-blue;
								  display: flex;
								  align-items: center;
								  justify-content: center;
								  height: 16px;
								  width: 16px;
								  border-radius: 100px;
								  position: absolute;
								  top: 4px;
								  right: 6px;
								  z-index: 1;
					  
								  svg {
									width: 8px !important;
									height: auto;
									position: relative;
					  
									path {
									  fill: $color-white;
									}
								  }
								}
							  }
						  }

						  .sold {
							background-color: $color-red;
							padding: 4px 10px;
							font-family: $font-nunito !important;
							border-top-right-radius: 4px;
							border-bottom-right-radius: 4px;
							position: absolute;
							left: 0;
							bottom: 0.4375rem;

							.text {
								color: $color-white;
								font-size: 0.6875rem;
								text-transform: capitalize;
								font-weight: $fw700;
								line-height: 1;
								display: block;
							}
						}
						
						//   @include media-breakpoint-up(md) {
							._actions {
								position: absolute;
								right: 0;
								height: $percent-100;
								width: 60px;
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								// opacity: 0;
								-webkit-transition: all 0.2s ease-in-out;
								-moz-transition: all 0.2s ease-in-out;
								-ms-transition: all 0.2s ease-in-out;
								-o-transition: all 0.2s ease-in-out;
								transition: all 0.2s ease-in-out;
							}
						//   }
					}

				}

			}

		}
	}
	
}