//====================================
// Root **
//====================================

div#root {
  position: relative;
}

//====================================
// Slick Slider
//====================================

.slick-slider {
  overflow: hidden;
  .slick-arrow {
    z-index: 9;
  }

  @include media-breakpoint-up(xs) {
    .slick-arrow {
      display: none !important;
    }
  }

  @include media-breakpoint-up(md) {
    &:hover {
      .slick-arrow {
        display: block !important;
      }
    }
  }

  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    height: 70px;
    width: 70px;
    background-color: rgba(180, 180, 180, 0.1);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    opacity: 0.4;
    border-radius: 100px;
    margin-left: 10px;

    &::before {
      content: "";
    }

    &:hover {
      opacity: 0.9;
    }
  }

  .slick-arrow.slick-prev {
    left: 0;
    background-image: url("#{$path-image}/arrow-prev.png");
  }

  .slick-arrow.slick-next {
    right: 0;
    background-image: url("#{$path-image}/arrow-next.png");
  }
}

//====================================
// User Avatar **
//====================================

._rounded-image__with-notification-icon {
  display: block;
  overflow: hidden;
  position: relative;

  img {
    border-radius: 100px;
  }
}

._rounded-image__with-notification-icon {
  ._notification-icon-mark {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    width: 12px;
    background-color: $color-white;
    position: absolute;
    border-radius: 100px;

    &::after {
      display: block;
      content: "";
      height: 8px;
      width: 8px;
      background-color: $color-blue;
      border-radius: 100px;
    }
  }

  ._notification-icon-mark._blue {
    &::after {
      background-color: $color-blue;
    }
  }

  ._notification-icon-mark._red {
    &::after {
      background-color: $color-red;
    }
  }
}

._rounded-image__with-notification-icon._medium {
  width: 38px;
  height: 38px;
  min-width: 38px;
  min-height: 38px;

  img {
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
  }

  ._notification-icon-mark {
    top: 0px;
    right: 0px;
  }
}

._rounded-image__with-notification-icon._1x {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;

  img {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
  }

  ._notification-icon-mark {
    top: 0px;
    right: 0px;
  }
}

._rounded-image__with-notification-icon._2x {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;

  img {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }

  ._notification-icon-mark {
    top: 1px;
    right: 1px;
  }
}

._rounded-image__with-notification-icon._3x {
  width: 68px;
  height: 68px;
  min-width: 68px;
  min-height: 68px;

  img {
    width: 68px;
    height: 68px;
    min-width: 68px;
    min-height: 68px;
  }

  ._notification-icon-mark {
    top: 1px;
    right: 1px;
  }
}

//====================================
// Message Box Icon
//====================================

.alert-icon-box {
  position: relative;
  width: 22px;
  height: 22px;

  svg {
    width: 19px !important;
    height: 19px;
  }

  ._notification-icon-mark {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    width: 12px;
    background-color: $color-white;
    position: absolute;
    border-radius: 100px;
    top: -6px;
    right: -4px;

    &::after {
      display: block;
      content: "";
      height: 8px;
      width: 8px;
      background-color: $color-blue;
      border-radius: 100px;
    }
  }

  ._notification-icon-mark._blue {
    &::after {
      background-color: $color-blue;
    }
  }

  ._notification-icon-mark._red {
    &::after {
      background-color: $color-red;
    }
  }
}

//====================================
// Responsive Font Size
//====================================

@media (min-width: 240px) {
  ._10px {
    font-size: 10px;
  }

  ._11px {
    font-size: 11px;
  }

  ._12px {
    font-size: 12px;
  }

  ._13px {
    font-size: 13px;
  }

  ._14px {
    font-size: 14px;
  }

  ._15px {
    font-size: 15px;
  }

  ._16px {
    font-size: 16px;
  }

  ._18px {
    font-size: 18px;
  }

  ._20px {
    font-size: 18px;
  }

  ._22px {
    font-size: 16px;
  }

  ._24px {
    font-size: 19px;
  }

  ._26px {
    font-size: 23px;
  }

  ._28px {
    font-size: 18px;
  }

  ._30px {
    font-size: 24px;
  }

  ._34px {
    font-size: 24px;
  }

  ._36px {
    font-size: 22px;
  }

  ._46px {
    font-size: 32px;
  }

  ._48px {
    font-size: 34px;
  }

  ._56px {
    font-size: 36px;
  }

  ._60px {
    font-size: 28px;
  }

  ._96px {
    font-size: 40px;
  }
}

@media (min-width: 992px) {
  ._20px {
    font-size: 20px;
  }

  ._22px {
    font-size: 22px;
  }

  ._24px {
    font-size: 24px;
  }

  ._26px {
    font-size: 26px;
  }

  ._28px {
    font-size: 28px;
  }

  ._30px {
    font-size: 30px;
  }

  ._32px {
    font-size: 32px;
  }

  ._34px {
    font-size: 34px;
  }

  ._36px {
    font-size: 36px;
  }

  ._46px {
    font-size: 46px;
  }

  ._48px {
    font-size: 48px;
  }

  ._56px {
    font-size: 50px;
  }

  ._60px {
    font-size: 52px;
  }

  ._96px {
    font-size: 66px;
  }
}

@media (min-width: 1200px) {
  ._56px {
    font-size: 56px;
  }

  ._60px {
    font-size: 60px;
  }

  ._96px {
    font-size: 96px;
  }
}

//====================================
// Layout
//====================================

// Use Media Queries With Mixins

@include media-breakpoint-up(xs) {
}
@include media-breakpoint-up(sm) {
}
@include media-breakpoint-up(md) {
}
@include media-breakpoint-up(lg) {
}
@include media-breakpoint-up(xl) {
}

.box {
  @include media-breakpoint-up(sm) {
    background-color: $color-white;
    width: $percent-100;
    border-color: $color-ice;
    border-width: 1px;
    border-style: solid;
    @include padding-box;
  }
}

.box-shadow {
  @include media-breakpoint-up(sm) {
    -webkit-box-shadow: 0 4px 14px 4px rgba(180, 180, 180, 0.1);
    box-shadow: 0 4px 14px 4px rgba(180, 180, 180, 0.1);
  }
}

.headline {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  @include media-breakpoint-up(xs) {
    font-size: 14px;
    justify-content: space-between;
  }

  @include media-breakpoint-up(md) {
    font-size: 18px;
    justify-content: flex-start;
  }

  a {
    font-weight: $fw400;
    margin-left: 15px;
    display: inline-block;

    @include media-breakpoint-up(xs) {
      font-size: 13px;
    }

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }
}

._title {
  @include media-breakpoint-up(xs) {
    font-size: 14px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}

//====================================
// Buttons
//====================================

.btn {
  display: inline-block;
  max-width: 300px;
  width: 100%;
  color: $color-white;
  padding: 0.469rem 0.75rem;
  border: 0;

  @include media-breakpoint-up(xs) {
    font-size: 12px;
    font-weight: $fw700;
  }
  @include media-breakpoint-up(md) {
    font-size: 13px;
  }

  &:hover {
    color: $color-white;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.btn.btn-primary {
  background-color: $color-blue;

  &:focus {
    background-color: $color-blue;
  }

  &:active {
    background-color: #122038 !important;
  }
}

.btn.btn-success {
  background-color: $color-green;
}

.btn.btn-red {
  background-color: $color-red;
}

.btn.btn-neutral {
  background-color: $color-nude;
}

.btn.btn-normal {
  background-color: $color-ice;
  color: $color-gray;
}

.btn.btn-cancel {
  background-color: $color-white;
  color: $color-gray;
}

.btn.btn-transparent {
  background-color: transparent;

  &:hover {
    color: $color-black;
  }
}

.btn.btn-red.btn-border {
  background-color: $color-white;
  color: $color-red;
  border: 1px solid rgba(194, 64, 63, 0.4);
}

.link-button {
  display: inline-block;
  color: $color-white;
  border: 1px solid $color-blue;
  padding: 0.125rem 0.999rem;
  border-radius: 4px;
  font-weight: $fw700;
  font-size: 12px;
  background-color: $color-blue;

  &:hover {
    color: $color-white;
  }
}

.btn.btn-fluid {
  width: $percent-100;
  max-width: none;
}

.btn.btn-submit-modal,
.btn.btn-step {
  @include media-breakpoint-up(md) {
    max-width: 280px;
    display: block;
    margin: auto;
  }

  svg {
    margin-left: 6px;
    path {
      fill: #ffffff;
    }
  }
}

.btn.disabled,
.btn:disabled {
  color: #777777;
  background-color: #e2e2e2;
  border-color: #e2e2e2;

  svg {
    path {
      fill: #cccccc !important;
    }
  }
}

//====================================
// Form
//====================================

.form {
  .form-group {
    label {
      margin: 0;
      display: block;
    }

    input {
      border: 1px solid #f1f1f1;
      font-size: 14px;
      font-weight: $fw400;
      border-radius: 5px;
      padding: 0.536em 0.836em;

      &::placeholder {
        color: #cbcbce;
        font-family: $font-poppins;
        font-weight: $fw600;
      }
    }
  }

  .form-group.search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    position: relative;

    @include media-breakpoint-up(xs) {
      flex-direction: row-reverse;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    .switch-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $color-ice;
      border-radius: 100px;
      position: absolute;

      @include media-breakpoint-up(xs) {
        right: 10px;
      }

      @include media-breakpoint-up(md) {
        right: 50px;
      }

      label {
        margin: 0;

        input[type="radio"],
        input[type="checkbox"] {
          display: none !important;
          opacity: 0 !important;
        }

        .mark {
          font-size: 10px;
          font-weight: $fw700;
          color: $color-gray;
          background-color: transparent;
          padding: 0.1rem 0.75rem;
          cursor: pointer;
        }

        input:checked ~ .mark {
          background-color: $color-blue;
          color: $color-white;
          border-radius: 100px;
        }
      }
    }

    input {
      width: 100%;
      font-weight: $fw700;

      @include media-breakpoint-up(xs) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-right-width: 1px;
        border-left-width: 0;
        padding: 0.536em 0.536em;
      }

      @include media-breakpoint-up(lg) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-right-width: 0;
        border-left-width: 1px;
        padding: 0.679em 1.136em;
      }

      &:focus,
      &:active {
        border-color: $color-ice-bold !important;
        // border-left: 0 !important;
      }
    }

    button {
      background-color: $color-white;
      border: 1px solid #f1f1f1;
      font-size: 14px;
      font-weight: $fw700;
      border-radius: 5px;

      @include media-breakpoint-up(xs) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;
        border-left-width: 1px;
        padding: 0.536em 0.536em;
      }

      @include media-breakpoint-up(lg) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right-width: 1px;
        border-left-width: 0;
        padding: 0.679em 0.936em;
      }

      svg {
        position: relative;
        top: 2px;
        width: 16px;
        height: 16px;

        path {
          fill: #212121;
          opacity: 0.7;
        }
      }
    }
  }
}

._wrap-form-panel {
  background-color: $color-white;
  padding-bottom: 15px;

  &:first-child {
    margin-top: 0;
  }

  ._set-user-location {
    margin: 0;
    margin-bottom: 15px;
    max-width: none;
  }

  ._form {
    padding: 15px 15px 0 15px;

    .form-group {
      @include media-breakpoint-up(xs) {
        padding-left: 0;
      }

      @include media-breakpoint-up(md) {
        padding-left: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
      ._character-counter {
        top: 35px;
      }

      .text-muted {
        font-weight: $fw700;
        font-size: 11px;
        color: #909093;
        padding-left: 0;
        opacity: 0.7;
      }

      ._wrap-custom-url {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .custom-url {
          background-color: $color-ice-bold;
          border: 1px solid #eaeaea;
          color: #8b8b8e;
          font-size: 13px;
          font-weight: $fw700;
          display: flex;
          align-items: center;
          justify-content: center;
          height: calc(1.462em + 0.75rem + 9px);
          padding: 0.375rem 0.75rem;
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }

        .form-control {
          border-left: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    .form-group-button {
      text-align: left;
    }
  }
}

//====================================
// Nav List
//====================================

nav._list-nav {
  ul {
    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: $color-gray-bolder;
        font-size: 12px;
        font-weight: $fw700;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          background-color: #f8f8f8;
          margin-right: 8px;
          border-radius: 100px;
          position: relative;

          svg {
            width: 16px !important;
            height: 16px;
            opacity: 0.8;
          }

          ._notification-icon-mark {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 12px;
            width: 12px;
            background-color: $color-white;
            position: absolute;
            border-radius: 100px;
            top: 4px;
            right: -4px;

            &::after {
              display: block;
              content: "";
              height: 8px;
              width: 8px;
              background-color: $color-blue;
              border-radius: 100px;
            }
          }

          ._notification-icon-mark._blue {
            &::after {
              background-color: $color-blue;
            }
          }

          ._notification-icon-mark._red {
            &::after {
              background-color: $color-red;
            }
          }
        }

        .count {
          height: 18px;
          width: 18px;
          min-width: 18px;
          border-radius: 100px;
          background-color: $color-red;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;

          h6 {
            font-size: 10px;
            font-weight: $fw700;
            color: $color-white;
            margin: 0;
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
}

//====================================
// aSide Menu
//====================================

.aside-menu {
  background-color: $color-white;
  position: fixed;
  height: 100%;
  width: 270px;
  top: 0;
  -webkit-box-shadow: 4px 0 8px 4px rgba(0, 0, 0, 0.03);
  box-shadow: 4px 0 8px 4px rgba(0, 0, 0, 0.03);
  border-right: 1px solid rgba(0, 0, 0, 0.03);
  z-index: map-get($z-index, aside-menu);
}

.aside-menu {
  .aside-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    width: 100%;
    font-weight: $fw700;
    border-bottom: 1px solid $color-ice;
    @include padding-lt-rt;
    padding: {
      top: 1.98rem;
      bottom: 0.8rem;
    }

    .btn {
      @include mobile-button-icon;
      max-width: none;
      width: auto;
      padding: 0;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .aside-menu-header._user {
    position: relative;
    background-color: $color-ice;
    padding: {
      top: 1rem;
      bottom: 1rem;
    }

    .close-user-menu {
      position: absolute;
      top: 0.9375rem;
      right: 0.9375rem;
    }

    .user-informations {
      button {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .user-informations-name__and-email {
          font-size: 15px;
          font-weight: $fw700;
          width: 100%;
          text-align: left;
          margin-left: 10px;

          span {
            font-size: 10px;
            display: block;
            opacity: 0.8;
          }
        }
      }
    }
  }

  .button-to-announce {
    @include padding-lt-rt;
    padding: {
      top: 1.35rem;
      bottom: 1.25rem;
    }

    button {
      font-size: 13px;
    }
  }

  nav {
    ul {
      li {
        a {
          @include padding-lt-rt;
        }
      }
    }
  }

  nav._user {
    ul {
      li {
        position: relative;

        span.tagged {
          background-color: $color-ice;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 15px;
          height: 18px;
          margin-top: auto;
          margin-bottom: auto;
          font-size: 10px;
          color: $color-black;
          font-weight: $fw700;
          opacity: 0.9;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0.4rem;
          border-radius: 50px;
        }

        &:last-child {
          a {
            color: $color-red;

            span {
              background-color: transparent;

              svg {
                path {
                  fill: $color-red;
                }
              }
            }
          }
        }
      }
    }
  }

  .aside-nav {
    ul {
      li {
        border-bottom: 1px solid $color-ice;

        a {
          font-size: 12px;
          padding: {
            top: 0.5rem;
            bottom: 0.5rem;
          }

          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          color: $color-gray-bolder;

          &:hover {
            background-color: $color-ice;
          }

          svg {
            width: 8px;
            height: 16px;
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.aside-menu._leftwards {
  left: 0;
}

.aside-menu._rightwards {
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 4rem;
  overscroll-behavior: contain;
}

//====================================
// Dropdown Menu ** Hover
//====================================

._dropdown-menu {
  position: absolute;
  top: 41px;
  right: -50px;
  background-color: $color-white;
  width: 290px;
  z-index: map-get($z-index, dropdown-user-menu);
  padding: 1.5625rem;
  border-radius: 2px;
  border: 1px solid $color-ice;
  -webkit-box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.04);
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.04);

  &::before {
    display: block;
    content: "\A";
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid $color-ice;
    position: absolute;
    top: -12px;
    right: 60px;
  }
  &::after {
    display: block;
    content: "\A";
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid $color-white;
    position: absolute;
    top: -11px;
    right: 60px;
  }
}

//====================================
// UI * Product
//====================================

._ui-item-wrapper {
  ._ui-item {
    display: block;
    border: 1px solid #f7f7f7;
    border-radius: 2px;

    @include media-breakpoint-up(xs) {
      //max-width: 145px;
    }

    @include media-breakpoint-up(md) {
      //max-width: 208px;
    }

    ._ui-item-image {
      width: 100%;
      background-color: $color-white;
      border-bottom: 1px solid #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      @include media-breakpoint-up(xs) {
        height: 160px;
      }

      @include media-breakpoint-up(sm) {
        height: 220px;
      }

      @include media-breakpoint-up(lg) {
        height: 191px;
      }

      figure {
        width: 300px;
        overflow: hidden;
        margin: 0;

        img {
          display: block;
          margin: 0 -6.885%;
          width: 111.111%;
        }
      }

      .tag-weight {
        position: absolute;
        left: 5px;
        bottom: 5px;
        background-color: $color-white;
        font-family: $font-nunito;
        font-size: 11px;
        font-weight: $fw700;
        color: $color-black;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-ice;
        padding: 0.25rem .3125rem;
        border-radius: 5px;
        line-height: 1;
      }
    }

    ._ui-item-bottom {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      padding: 10px 10px 5px 10px;
      position: relative;

      ._col-left {
        ._product-title,
        ._product-price {
          color: $color-black;
        }

        ._product-title {
          font-weight: $fw700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;

          @include media-breakpoint-up(xs) {
            font-size: 11px;
            width: 118px;
          }

          @include media-breakpoint-up(sm) {
            font-size: 12px;
          }

          @include media-breakpoint-up(xl) {
            font-size: 12px;
            width: 172px;
          }

          &::after {
            display: block;
            content: "";
            height: 14px;
            width: 20px;
            position: absolute;
            right: 0;
            background: rgb(255, 255, 255);
            background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 1) 100%);
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
          }
        }
      }

      ._col-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;

        button {
          width: auto;
          padding: 0.5rem 0.8rem;
          background: $color-white;
          line-height: 0;
          position: relative;

          &:first-child {
            border-right: 1px solid #f7f7f7;
          }

          &:last-child {
            padding-right: 0.4rem;
          }

          svg {
            width: 18px;
            height: 18px;
            -webkit-transition: all 0.15s ease-in-out;
            -moz-transition: all 0.15s ease-in-out;
            -ms-transition: all 0.15s ease-in-out;
            -o-transition: all 0.15s ease-in-out;
            transition: all 0.15s ease-in-out;

            path {
              fill: $color-red;
              opacity: 0.7;
            }
          }

          .check {
            background-color: $color-blue;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 16px;
            width: 16px;
            border-radius: 100px;
            position: absolute;
            top: 4px;
            right: 6px;
            z-index: 1;

            svg {
              width: 8px !important;
              height: auto;
              position: relative;

              path {
                fill: $color-white;
              }
            }
          }
        }

        button.active {
          svg {
            transform: scale(1.1);
            -webkit-transition: all 0.15s ease-in-out;
            -moz-transition: all 0.15s ease-in-out;
            -ms-transition: all 0.15s ease-in-out;
            -o-transition: all 0.15s ease-in-out;
            transition: all 0.15s ease-in-out;
          }
        }

        button._disabled {
          svg {
            path {
              opacity: 0.7;
            }
          }
        }

        ._product-price._1x.real {
          
          .ui-item__price {

            .price-tag-symbol {
              margin-top: 2px;
            }
          }
        }
      }

      ._col-bottom {

        display: flex;
        justify-content: flex-start;
        margin-top: 4px;
        
        @include media-breakpoint-up(xs) {
          align-items: flex-start;
          flex-direction: column;
        }
        
        @include media-breakpoint-up(lg) {
          align-items: center;
          flex-direction: row;
        }

        .text,
        .price {
          font-weight: $fw700;
          color: $color-black;
        }

        .text {
          font-size: 10px;
          opacity: 0.8;
          line-height: 1;
        }
        
        .price {
          font-size: 11px;

          @include media-breakpoint-up(xs) {
            margin-top: 2px;
          }
    
          @include media-breakpoint-up(lg) {
            margin-left: 3px;
          }
        }

        .wrap-cashback {
          position: relative;
          width: 100%;
          display: block;
          margin-top: 25px;
          margin-left: -11px;
          margin-bottom: -6px;

        .cashback-tag {
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: $color-ice-light;
          border: 1px solid $color-ice;
          padding: 2px 8px;
          border-top-right-radius: 5px;
          
          .text {
            font-size: 0.6875rem;
            margin-right: 3px;
          }

          .text,
          .price {
            font-family: $font-nunito !important;
            color: $color-blue;
            opacity: 0.9;
          }
        }
        }

        .cashback-tag.no {
          padding: 5px 8px;
        }
      }
    }

  }
}

//====================================
// UI * Slick Slider
//====================================

.slick-dots {
  li {
    height: auto;
    width: auto;
    margin: 0 0;

    button {
      width: auto;
      height: auto;

      &::before {
        content: none;
        position: relative;
        left: auto;
        top: auto;
        border-radius: 100px;
        opacity: 0.85;
      }
    }
  }

  li {
    button {
      &::before {
        display: block;
        content: "";
        width: 6px;
        height: 6px;
        background-color: $color-silver;
      }
    }
  }

  .slick-active {
    button {
      &::before {
        width: 16px;
        height: 6px;
        background-color: $color-nude;
        opacity: 1 !important;
      }
    }
  }
}

.inline-slider {
  .slick-list {
    .slick-track {
      display: flex;

      .slick-slide {
        box-sizing: border-box;
        width: auto !important;

        @include media-breakpoint-up(xs) {
          padding-left: 0;
          padding-right: 15px;
        }

        @include media-breakpoint-up(sm) {
          padding-left: 10px;
        }
      }
    }
  }
}

._recently-added {
  .slick-list {
    .slick-track {
      display: flex;

      .slick-slide {
        box-sizing: border-box;
        width: auto !important;
        margin-right: 30px;
        margin-left: -15px;

        &:first-child {
          padding-left: 0;
        }

        ._ui-item-wrapper {
          @include media-breakpoint-up(xs) {
            width: 136px !important;
          }

          @include media-breakpoint-up(md) {
            width: 206px !important;
          }
        }
      }
    }
  }
}

//====================================
// UI * Facebook Auth Btn
//====================================

._wrap-auth-facebook {
  ._auth-facebook-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 227px;
    text-align: left;
    background-color: #38589a;
    color: $color-white;
    font-weight: $fw700;
    font-size: 12px;
    border-radius: 100px;
    margin: 0 auto;

    .btn-icon {
      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #4161a2;
      border-radius: 100px;
      -webkit-box-shadow: 0 4px 14px 4px rgba(180, 180, 180, 0.1);
      box-shadow: 0 4px 14px 4px rgba(180, 180, 180, 0.1);
      margin-right: 10px;

      svg {
        height: 21px;
        width: auto;

        path {
          fill: $color-white;
        }
      }
    }
  }

  ._auth-facebook-btn._create-acc {
    max-width: 260px;
  }
}

//====================================
// UI * Radio Button Inline
//====================================

._wrap-check-radio {
  ._check-radio {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 12px;
    font-weight: $fw700;
    border: 1px solid $color-ice-bold;
    height: 90px;
    width: 90px;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    padding-top: 15px;

    .overlay {
      display: none;
      height: 100%;
      width: 100%;
      border: 0;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 3px;
    }

    .form-check-input {
      display: none;
    }

    ._wrap-alt-icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      color: $color-gray;
      font-size: 11px;
      text-align: center;

      ._alt-icon {
        height: 24px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;

        svg {
          height: 18px;
          width: auto;

          path {
            fill: $color-gray;
            opacity: 0.7;
          }
        }
      }
    }

    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      width: 18px;
      min-width: 18px;
      border-radius: 3px;
      border: 1px solid $color-ice;
      background-color: $color-white;
      cursor: pointer;
      position: absolute;
      top: 4px;
      right: 4px;

      svg {
        width: 11px;
        height: auto;

        path {
          fill: #f1f1f1;
        }
      }
    }

    .form-check-input:checked ~ .check {
      background-color: $color-blue;
      border-color: $color-white;
      svg {
        path {
          fill: $color-white;
        }
      }
    }

    // .form-check-input:checked ~ ._wrap-alt-icon {
    //   color: $color-blue;
    // }

    // .form-check-input:checked ~ ._wrap-alt-icon > ._alt-icon {
    //   background-color: $color-blue;
    //   svg {
    //     path {
    //       fill: $color-white;
    //       opacity: 1;
    //     }
    //   }
    // }
    // .form-check-input:checked ~ .overlay {
    //   display: block;
    //   border: 1px solid $color-blue;
    // }
  }
}
._wrap-check-radio.radio {
  ._check-radio {
    .check {
      border-radius: 100px;
    }
  }
}

//====================================
// UI * Photos
//====================================

._photos {
  ._label {
    .text-muted {
      padding-left: 0 !important;
    }
  }

  .form-control-file,
  .form-control-file::-webkit-file-upload-button {
    visibility: hidden;
    display: none;
  }

  ._wrap-photo-item.row {
    margin: 0;
  }
  ._wrap-photos {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    ._wrap-photo-item {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }

      ._photo-item {
        border: 1px dashed $color-ice-bold;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: $color-white;
        position: relative;
        overflow: hidden;
        margin: 0;
        padding: 0;

        @include media-breakpoint-up(xs) {
          width: 52px;
          height: 50px;
        }

        @include media-breakpoint-up(md) {
          width: 100px;
          height: 100px;
        }

        .photo-count {
          position: absolute;
          top: 3px;
          right: 3px;
          z-index: 100;
          background-color: $color-white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6.25rem;
          
          @include media-breakpoint-up(xs) {
            height: 16px;
            width: 16px;
            min-width: 16px;
          }
          
          @include media-breakpoint-up(md) {
            height: 18px;
            width: 18px;
            min-width: 18px;
          }

          span {
            position: relative;
            top: 1px;
            font-weight: 700;
            line-height: 1;
            font-family: $font-nunito;
            color: $color-blue;
            
            @include media-breakpoint-up(xs) {
              font-size: 8px;
            }
            
            @include media-breakpoint-up(dm) {
              font-size: 10px;
            }
          }
        }

        ._image-selected {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $color-white;
          z-index: map-get($z-index, z-default);

          @include media-breakpoint-up(xs) {
            height: 110%;
          }

          @include media-breakpoint-up(md) {
            height: $percent-100;
          }

          img,
          canvas {
            width: 110%;
            position: absolute;
            top: 50%;
            left: 50%;
            transition: 300ms all ease;
            -webkit-transform: translate(-50%, -54%);
            transform: translate(-50%, -54%);
            z-index: 1;
          }

          img._landscape {
            height: 110px;
            width: auto;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }

          img._portrait {
            width: 110%;
            -webkit-transform: translate(-50%, -54%);
            transform: translate(-50%, -54%);
          }

          img._square {
            width: 110%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }

          // ._delete-image {
          //   position: absolute;
          //   top: 0;
          //   bottom: 0;
          //   left: 0;
          //   right: 0;
          //   margin-top: auto;
          //   margin-bottom: auto;
          //   margin-left: auto;
          //   margin-right: auto;
          //   background-color: $color-white;
          //   border-radius: 100%;
          //   padding: 0;
          //   display: none;
          //   align-items: center;
          //   justify-content: center;
          //   border: 1px solid #f9f9f9;
          //   z-index: map-get($z-index, z-default);
            
          //   @include media-breakpoint-up(xs) {
          //     width: 32px;
          //     height: 32px;
          //   }
            
          //   @include media-breakpoint-up(md) {
          //     width: 36px;
          //     height: 36px;
          //   }

          //   svg {
          //     height: auto;
              
          //     @include media-breakpoint-up(xs) {
          //       width: 13px;
          //     }
              
          //     @include media-breakpoint-up(md) {
          //       width: 14px;
          //     }

          //     path {
          //       fill: $color-gray-bolder;
          //     }
          //   }
          // }

          @include media-breakpoint-up(xs) {
            ._delete-image {
              display: flex;
            }
          }

          &:hover {
            @include media-breakpoint-up(lg) {
              ._delete-image {
                display: flex;
              }
            }
          }
        }

        ._image-selected.no-photo {
          visibility: hidden !important;
          display: none !important;
        }

        &:hover {
          border: 1px dashed $color-gray;
        }

        label {
          text-align: center;
          cursor: pointer;
          margin: 0;

          svg {
            width: 18px;
            height: auto;
            opacity: 0.9;

            path {
              fill: $color-blue;
            }
          }

          .upload-icon {
            position: relative;
            top: 2px;

            svg.camera-icon {
              
              @include media-breakpoint-up(xs) {
                width: 20px;
              }
              
              @include media-breakpoint-up(md) {
                width: 22px;
              }
            }

            .plus {
              position: absolute;
              background-color: $color-white;
              height: 18px;
              width: 18px;
              border-radius: 100px;
              border: 1px solid $color-white;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                height: auto;
                
                @include media-breakpoint-up(xs) {
                  width: 7px;
                }
                
                @include media-breakpoint-up(md) {
                  width: 9px;
                }
              }

              @include media-breakpoint-up(xs) {
                top: -5px;
                right: -8px;
              }
  
              @include media-breakpoint-up(md) {
                top: -6px;
                right: 19px;
              }
            }
          }

          ._title-label,
          ._accept-formats-label {
            color: $color-blue;
            opacity: 0.6;
            font-weight: $fw700;

            @include media-breakpoint-up(xs) {
              display: none;
            }

            @include media-breakpoint-up(md) {
              display: block;
            }
          }

          ._title-label {
            margin-bottom: 0;
            font-size: 8px;
          }

          ._accept-formats-label {
            font-size: 6px;
          }
        }
      }

      ._photo-item.first-item-upload {
        
        @include media-breakpoint-up(xs) {
          width: 52px;
          height: 50px;
        }
        
        @include media-breakpoint-up(md) {
          width: 100px;
          height: 100px;
        }
      }

      ._delete-image {
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        background-color: transparent;
        border-radius: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        z-index: map-get($z-index, z-default);
        
        @include media-breakpoint-up(xs) {
          width: 32px;
          height: 32px;
        }
        
        @include media-breakpoint-up(md) {
          width: 36px;
          height: 36px;
        }

        svg {
          height: auto;
          width: 0.9375rem;

          path {
            fill: $color-red;
          }
        }
      }
    }

    ._wrap-photo-item._upload-action {
      padding-top: 8px;
      padding-bottom: 16px;
    }
  }
  
}

//====================================
// UI * Input Helper
//====================================

._hidden,
._hidden::-webkit-file-upload-button {
  visibility: hidden;
  display: none;
}

//====================================
// UI * Account Action Buttons
//====================================

._action-buttons {
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  button {
    border: 1px solid $color-ice;
    background-color: $color-white;
    color: $color-gray;
    padding: 0.219rem 0.494rem;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: none;
    width: auto;
    font-size: 13px;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: $color-gray;
    }

    svg {
      margin-left: 5px;
      path {
        fill: $color-gray;
      }
    }
  }
}

//====================================
// UI * Bootstrap Nav Tabs
//====================================

.nav.nav-tabs {
  .nav-item.nav-link {
    border: 0 !important;
    margin-bottom: 0;
    color: $color-gray;

    &:focus,
    &:hover {
      border: 0;
      outline: 0;
      box-shadow: none;
    }
  }

  .nav-item.nav-link.active {
    border: 0;
    border-bottom: 2px solid $color-blue !important;
    color: $color-black;
    font-weight: $fw700;
  }
}

//====================================
// UI * Bootstrap Accordion Payment
//====================================

._accordion {
  .card {
    border: 0;

    .card-header {
      background-color: transparent;
      border: 0;
      padding: 0;
      border-top: 1px solid $color-ice-bold;

      button.btn {
        color: $color-black;
        max-width: none;
        width: 100%;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover,
        &:visited,
        &:focus {
          text-decoration: none;
          outline: 0;
        }

        button.toggle-arrow {
          max-width: none;
          width: auto;

          @include media-breakpoint-up(md) {
            margin-left: 15px;
          }

          svg {
            height: 16px;
            width: auto;

            path {
              fill: $color-gray;
            }
          }
        }
      }
    }

    .collapse {
      border-top: 1px solid $color-ice-bold;
    }
  }
}

._accordion.small {
  .card {
    border: 0;

    .card-header {
      background-color: transparent;
      border: 0;
      padding: 0;
      border-top: 1px solid $color-ice-bold;

      button.btn {
        padding: 8px;
        padding-left: 15px;
        padding-right: 15px;

        ._accordion-title {
          font-size: 13px;
          font-weight: $fw700;
        }

        button.toggle-arrow {
          padding-right: 10px;
          svg {
            width: 14px;
          }
        }
      }
    }

    .collapse {
      .card-body {
        line-height: 1.8;
        font-weight: $fw700;
        color: $color-black;
        font-size: 13px;
      }
    }
  }
}

//====================================
// UI * Filter Activity
//====================================

._filter-activity {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @include media-breakpoint-up(xs) {
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  ._form {
    display: flex;
    justify-content: flex-start;
    width: $percent-100;
    @include padding-lt-rt;
    padding-bottom: 0;
    
    @include media-breakpoint-up(xs) {
      align-items: flex-start;
      flex-direction: column;
    }
    
    @include media-breakpoint-up(md) {
      align-items: stretch;
      flex-direction: row;
    }

    .dates {
      display: flex;

      @include media-breakpoint-up(xs) {
        width: $percent-100;
        margin-bottom: 10px;
      }

      @include media-breakpoint-up(md) {
        width: auto;
        margin-bottom: 0;
      }

      ._wrap-input-group {
        border: 1px solid $color-ice-bold;

        border-radius: 4px;
        padding-top: 4px;
        padding-bottom: 2px;
        padding-left: 10px;
        padding-right: 1px;
        background-color: $color-white;
        margin-left: 10px;
        min-height: 62px;
        width: $percent-100;

        @include media-breakpoint-up(md) {
          max-width: 185px;
        }

        &:first-child {
          margin-left: 0;
        }

        @include media-breakpoint-up(xs) {
          min-width: 125px;
        }

        @include media-breakpoint-up(md) {
          min-width: 185px;
        }

        ._title-label-input {
          font-size: 10px;
          font-weight: $fw700;
          color: $color-blue;
          margin: 0;
        }

        .input-group {
          .form-control {
            border: 0;
            height: calc(1.462em + 0.75rem + 0px);
            color: $color-black;
            padding-left: 0;
            font-size: 13px;
            color: $color-blue;
            font-weight: $fw700;
            font-family: $font-nunito !important;

            &:focus,
            &:active {
              border: 0 !important;
            }
          }

          .input-group-prepend {
            display: none;
            .input-group-text {
              border: 0;
              padding: 0.375rem 0.75rem;
              position: relative;
              top: -2px;

              span.icon {
                display: flex;
                background: url(https://cdn3.iconfinder.com/data/icons/linecons-free-vector-icons-pack/32/calendar-256.png)
                  center/80% no-repeat;
                color: rgba(0, 0, 0, 0);
                opacity: 0.5;
                width: 21px;
                height: 21px;
              }

              svg {
                height: 18px;
                width: auto;

                path {
                  fill: $color-blue;
                }
              }
            }
          }
        }
      }
    }

    .form-group.search {
      width: $percent-100;
      color: $color-blue;

      @include media-breakpoint-up(md) {
        margin-left: 10px;
      }

      .form-control {
        font-weight: $fw700;

        &::placeholder {
          font-weight: $fw400 !important;
        }

        &:focus {
          border: 1px solid $color-ice-bold !important;
          @include media-breakpoint-up(xs) {
            border-left: 0 !important;
          }
          @include media-breakpoint-up(md) {
            border-right: 0 !important;
            border-left: 1px solid $color-ice-bold !important;
          }
        }
      }

      .form-control,
      button {
        background-color: $color-white;

        @include media-breakpoint-up(xs) {
          height: calc(1.5em + 0.75rem + 9px);
          min-height: 42px;
        }

        @include media-breakpoint-up(md) {
          height: calc(1.5em + 0.75rem + 28px);
          min-height: 62px;
        }
      }

      button {
        svg {
          height: 15px;
          width: auto;

          path {
            fill: $color-gray;
          }
        }
      }
    }

    button.apply-filters {

      @include media-breakpoint-up(xs) {
        margin-top: 10px;
      }

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 10px;
        max-width: 120px;
      }

      svg {
        path {
          fill: $color-white;
        }
      }
    }
  }
}

//====================================
// UI * Payment Methods List
//====================================

._payment-methods-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  li {
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }

    .creadit-card--flag {
      width: 28px;
      height: 20px;
      border-radius: 2px;
      border: 1px solid $color-ice;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        min-width: 20px;
        height: auto;
      }
    }
  }
}

._set-user-location {
  background-color: $color-box;
  max-width: 488px;
  padding: 15px;
  border-radius: 5px;
  position: relative;

  ._user-location-title {
    font-size: 15px;
    font-weight: $fw700;
    color: $color-gray;
    margin-bottom: 10px;
  }

  ._wrap-location {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    ._icon {
      background-color: $color-white;
      height: 32px;
      width: 32px;
      min-width: 32px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 14px;
        width: auto;

        path {
          fill: $color-blue;
        }
      }
    }

    ._wrap-current-location {
      margin-left: 10px;
      color: #7d7d81;
      font-weight: $fw700;

      ._location-title {
        font-size: 14px;
        margin-bottom: 5px;
      }

      ._location {
        font-weight: $fw400;
        font-size: 11px;
      }
    }
  }
}

//=============================================================================================
// UI * Custom Select With Chebox, Radio
//=============================================================================================

._wrap-form-select {
  ._label {
    margin-bottom: 5px;
  }

  ._form-select {
    border-color: #eaeaea;
    border-width: 1px;
    border-style: solid;
    border-radius: 0.25rem;
    margin-bottom: 1rem;

    ._the-label {
      font-size: 13px;
      height: calc(1.462em + 0.75rem + 9px);
      font-weight: $fw700;
      color: $color-gray;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.375rem 0.75rem;
      cursor: pointer;
      border-bottom: 1px solid #eaeaea;

      span.caret {
        svg {
          width: 13px;
          height: auto;

          path {
            fill: $color-gray;
          }
        }
      }
    }

    ._form-select-content {
      ._select-item {
        border-bottom: 1px solid #eaeaea;

        &:last-child {
          border-bottom: 0;
        }

        &:last-child {
          label.ui-checkbox {
            ._select-content {
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }

        label.ui-checkbox {
          width: 100%;
          margin: 0;
          cursor: pointer;

          input._checkbox {
            display: none;
            visibility: hidden;
          }

          ._select-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.625rem 0.75rem;

            ._item-name {
              font-size: 12px;
              color: $color-gray-bolder;
              font-weight: $fw700;
            }

            ._item-icon {
              svg {
                width: 8px;
                height: auto;

                path {
                  fill: $color-gray;
                }
              }
            }
          }

          input._checkbox:checked ~ ._select-content {
            background-color: $color-blue;

            ._item-name {
              color: $color-white;
            }

            ._item-icon {
              svg {
                path {
                  fill: $color-white;
                }
              }
            }
          }
        }
      }
    }
  }
}

//=============================================================================================
// UI * Custom Bootstrap Modal
//=============================================================================================

.bootstrap-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        border: 0;
      }

      .modal-body {
        ._modal-title {
          font-size: 16px;
          font-weight: $fw700;
          color: $color-black;
          text-align: center;
          max-width: 320px;
          margin: 0 auto;
          margin-bottom: 30px;

          ._modal-subtitle {
            display: block;
            font-size: 14px;
            font-weight: $fw400;
            color: $color-gray;
            margin-top: 5px;
          }
        }

        ._wrap-content {
          .proposal-product {
            ._form {
              ._proposal-product {
                border: 1px solid $color-ice;

                .product-image {
                  height: 40px;
                  width: 40px;
                  overflow: hidden;
                  border-radius: 4px;

                  img {
                    height: $percent-100;
                    width: auto;
                  }
                }

                ._store-title {
                  font-size: 11px;
                  font-weight: $fw700;
                  color: $color-black;
                }
              }
            }
          }
        }
      }
    }
  }
}

//=============================================================================================
// UI * List
//=============================================================================================

._list-info {
  li {
    border-bottom: 1px solid #f9f9f9;
    padding: 6px 0;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(xs) {
      align-items: flex-start;
      flex-direction: column;
    }

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row;
    }

    &:first-child {
      border-top: 2px solid #f9f9f9;
    }

    &:last-child {
      padding-bottom: 0;
    }

    .key,
    .val {
      font-size: 13px;
      font-weight: $fw700;
      color: $color-gray;
    }

    .val {
      color: $color-gray-bolder;
      @include media-breakpoint-up(xs) {
        font-size: 11px;
        margin-top: 5px;
      }

      @include media-breakpoint-up(md) {
        font-size: 13px;
      }
    }

    .key {
      .val {
        color: $color-black;

        @include media-breakpoint-up(xs) {
          font-size: 14px;
        }
        @include media-breakpoint-up(md) {
          font-size: 18px;
        }
      }

      .val.link {
        @include media-breakpoint-up(xs) {
          font-size: 13px;
        }
        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
        button {
          max-width: none;
          width: auto;

          path {
            fill: $color-gray;
          }
        }
      }
    }
  }
}

//=============================================================================================
// UI * User Rating Stars
//=============================================================================================

.rating-stars {
  margin-top: -2px;
  span {
    svg {
      width: auto;
      height: 12px;
    }
  }

  span.star-1 {
    svg {
      path {
        fill: $color-yellow;
      }
    }
  }

  span.star-0 {
    svg {
      path {
        fill: $color-gray;
      }
    }
  }
}

//=============================================================================================
// UI * Message Box
//=============================================================================================

._message-box {
  background-color: $color-box;
  border: 1px solid #fbfbfb;
  padding: 15px;
  border-radius: 5px;

  ._title {
    font-size: 15px;
    font-weight: $fw700;
    color: $color-gray-bolder;
    margin-bottom: 10px;
  }

  ._wrap-content-box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    ._icon {
      background-color: $color-ice;
      height: 32px;
      width: 32px;
      min-width: 32px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 15px;
        width: auto;

        path {
          fill: $color-gray;
        }
      }
    }

    ._icon.info {
      svg {
        path {
          fill: $color-green;
        }
      }
    }

    ._wrap-texts {
      margin-left: 10px;
      color: #7d7d81;
      font-weight: $fw700;

      ._title {
        font-size: 14px;
        margin-bottom: 5px;
      }

      ._text {
        font-size: 12px;
        max-width: 420px;

        .actions {
          margin-top: 15px;
          display: flex;

          a.btn {
            font-size: 12px;
            max-width: 130px;

            &:last-child {
              margin-left: 10px;
            }
          }

          a.btn-primary {
            svg {
              path {
                fill: $color-white;
              }
            }
          }
        }
      }
    }
  }
}

//=============================================================================================
// UI * Swal
//=============================================================================================

.swal-footer {
  text-align: center !important;
  .swal-button-container {
    .swal-button {
      font-weight: 400 !important;
      font-size: 12px !important;
      padding: 6.5px 24px !important;
      border-radius: 4px !important;
    }

    .swal-button.swal-button--cancel {
      background-color: #f1f1f1 !important;
      color: #777777 !important;
    }

    .swal-button--cancel:focus,
    .swal-button--danger:focus {
      box-shadow: none !important;
    }
  }
}

.swal2-actions {
  .swal2-confirm,
  .swal2-styled {
    font-weight: 400 !important;
    font-size: 12px !important;
    padding: 6.5px 24px !important;
    border-radius: 4px !important;
  }

  .swal2-confirm.swal2-styled:focus {
    outline: 0;
    box-shadow: none !important;
  }

  .swal2-styled.swal2-cancel {
    background-color: #f1f1f1 !important;
    color: #777777 !important;
    font-weight: $fw700 !important;
  }
}

.swal2-file:focus,
.swal2-input:focus,
.swal2-textarea:focus {
  border: 1px solid #d9d9d9 !important;
  outline: 0;
  box-shadow: none !important;
}

//=============================================================================================
// UI * Filter - Store - Search results
//=============================================================================================

.wrap-filter-loader {
  @include media-breakpoint-up(xs) {
    display: none !important;
  }

  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.filter {
  @include media-breakpoint-up(xs) {
    width: $percent-100;
  }

  @include media-breakpoint-up(lg) {
    // width: 275px;
    // border-right: 1px solid $color-ice;
    // margin-right: 15px;
    padding-right: 15px;
    // min-height: 575px;
    display: flex;
    margin-top: 20px;
  }

  .filter-loader {
    margin-bottom: 30px;
  }

  ._apply-finter-button {
    text-align: center;

    @include media-breakpoint-up(xs) {
      margin-top: 30px;
      margin-bottom: 15px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 25px;
      margin-bottom: 15px;
    }

    button.apply-filters {
      font-weight: $fw700;

      @include media-breakpoint-up(xs) {
        padding: 0.688rem 0.75rem;
      }

      @include media-breakpoint-up(lg) {
        padding: 0.469rem 0.75rem;
      }

      svg {
        margin-left: 5px;
        path {
          fill: $color-white;
        }
      }
    }
  }

  ._active-filters--mobile {
    width: $percent-100;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    background-color: $color-ice;

    @include media-breakpoint-up(xs) {
      display: flex;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    button {
      margin: 0;
      padding: 0.657rem;
      background-color: $color-white;
      border: 0;
      width: 100%;
      font-size: 13px;
      font-weight: $fw700;
      color: $color-gray-bolder;
      border-bottom: 1px solid $color-ice;

      &:first-child {
        margin-left: -15px;
        border-right: 1px solid $color-ice;

        svg {
          width: 7px;
          height: auto;

          &:first-child {
            margin-left: 4px;
          }

          path {
            fill: $color-blue;
            opacity: 0.7;
          }
        }
      }

      &:last-child {
        margin-right: -15px;

        svg {
          width: 16px;
          height: auto;
          margin-left: 4px;

          path {
            fill: $color-blue;
            opacity: 0.7;
          }
        }
      }

      img {
        margin-left: 5px;
      }
    }
  }

  // Form Order
  ._filter-order._filter-show  {
    margin-bottom: 0.9375rem;
    
    ._filter-head {
      .header-open {

        .default-title {
          justify-content: center;
        }
      }
    }

    form {
      ._filter._filter-wrapper  {

        button._filter-button {
          
          @include media-breakpoint-up(xs) {
            display: none;
          }
          
          @include media-breakpoint-up(lg) {
            display: block;
          }
        }

        ._filter-item {
          display: block !important;

          ._filter-label {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  ._wrap-filters,
  ._filter-order {
    background-color: $color-white;
    width: $percent-100;

    @include media-breakpoint-up(xs) {
      z-index: map-get($z-index, filter);
      position: fixed;
      top: 0;
      left: 0;
      height: $percent-100;
      display: none;
    }

    @include media-breakpoint-up(lg) {
      z-index: auto;
      position: initial;
      top: auto;
      left: auto;
      height: auto;
      display: block;
    }

    ._filter-head {
      background-color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: $percent-100;
      z-index: 1;

      @include media-breakpoint-up(xs) {
        height: 50px;
      }
      @include media-breakpoint-up(lg) {
        height: auto;
        display: none;
      }

      ._back-history {
        height: 50px;
        width: 38px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;

        @include media-breakpoint-up(xs) {
          height: 50px;
        }
        @include media-breakpoint-up(lg) {
          height: 40px;
        }

        svg {
          width: 8px;
          height: auto;

          path {
            fill: $color-gray;
          }
        }
      }

      ._back-history {
        @include media-breakpoint-up(xs) {
          display: flex;
        }
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      ._title {
        font-weight: $fw700;
        color: $color-gray-bolder;
        font-size: 14px;
      }

      .header-open {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $color-ice;
          padding: 0.3125rem 0.9375rem;
          width: $percent-100;

          .back {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-width: 120px;

            .button-title {
                font-weight: $fw700;
                color: $color-blue;
                font-size: 11px;
                text-transform: uppercase;
                font-family: $font-nunito !important;
                margin-left: 10px;
            }

            svg {
                height: 20px;
                width: auto;

                path {

                    fill: $color-blue;
                    opacity: 1;
                }
            }
        }

          .default-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-width: 100px;
            

            .title {
                font-weight: $fw700;
                color: $color-blue;
                font-size: 11px;
                text-transform: uppercase;
                font-family: $font-nunito !important;
                margin-right: 5px;
            }

            svg {
                height: 16px;
                width: auto;

                path {

                    fill: $color-blue;
                    opacity: 1;
                }
            }
        }

          .clear-filters {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              padding-left: 15px;

              button {
                  font-weight: $fw700;
                  color: #DC3545;
                  font-size: 12px;
              }

              svg {
                  height: 20px;
                  width: auto;

                  path {

                      fill: $color-blue;
                      opacity: 1;
                  }
              }
          }

          .close-filter {
              min-width: 100px;
              text-align: right;
          }
      }
      
    }
  }

  ._filter-order {

    @include media-breakpoint-up(lg) {
      width: auto;
      margin-right: 15px;
    }

    ._filter-head {
      z-index: 1000;
    }

    ._form-order-by {

      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
      }

      ._filter {

        ._filter-button {
          
          @include media-breakpoint-up(lg) {
            border: 1px solid $color-ice-bold;
            padding: 12px 15px;
            border-radius: 5px;
            margin-bottom: 0;
            min-width: 126px;
          }

            ._cols {

              ._col-1 {

                .button-title {
                  @include media-breakpoint-up(lg) {
                    margin-right: 6px;
                  }
                }

                svg {
                  @include media-breakpoint-up(lg) {
                    transform: rotate(90deg);
                  }
                }
              }

              ._col-2 {
                @include media-breakpoint-up(lg) {
                  display: none;
                }
              }
            }
        }

      }

      ._filter._filter-wrapper {
        // z-index: 999;
      }
    }

    @include media-breakpoint-up(lg) {
      
    }
  }

  ._wrap-filters {

    @include media-breakpoint-up(lg) {
      position: relative;
    }

    #form-filters {
      padding-bottom: 0;

      @include media-breakpoint-up(lg) {
        display: flex;
      }


      ._filter {

        @include media-breakpoint-up(lg) {
          margin-left: 15px;

          &:nth-child(2) {
            margin-left: 0;
          }
        }
        

        ._filter-button {
          
          @include media-breakpoint-up(lg) {
            border: 1px solid $color-ice-bold;
            padding: 12px 15px;
            border-radius: 5px;
          }

            ._cols {

              ._col-1 {

                .button-title {
                  @include media-breakpoint-up(lg) {
                    margin-right: 6px;
                  }
                }

                svg {
                  @include media-breakpoint-up(lg) {
                    transform: rotate(90deg);
                  }
                }
              }

              ._col-2 {
                @include media-breakpoint-up(lg) {
                  display: none;
                }
              }
            }
        }
      }

      button.clear-filters-desk {
        font-weight: $fw700;
        color: #DC3545;
        font-size: 12px;
        margin-left: 15px;
        white-space: nowrap;

        @include media-breakpoint-up(xs) {
          display: none;
          visibility: hidden;
        }

        @include media-breakpoint-up(lg) {
          display: block;
          visibility: visible;
        }
      }
    }

    .count-results {
      font-size: 11px;
      font-weight: $fw700;
      font-family: $font-nunito !important;
      color: $color-blue;
      text-transform: uppercase;

      @include media-breakpoint-up(xs) {
        display: none;
      }
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }
}

._wrap-filters._filter-show,
._filter-order._filter-show {
  display: block !important;
}

.btn-select--filter {
  border: 0;
  width: $percent-100;
  background-color: $color-white;
  font-size: 13px;
  font-weight: $fw700;
  padding: 0.657rem 0.5rem;
  border-radius: 4px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-gray-bolder;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  overflow: hidden;

  span {
    display: block;
    min-width: 44px;
  }

  svg {
    opacity: 1;
    visibility: hidden;
    margin-left: 10px;
    position: relative;
    right: -50px;
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    width: 20px;
  }

  &:hover {
    background-color: $color-ice;

    svg {
      opacity: 1;
      visibility: visible;
      right: 0;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      -ms-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;

      path {
        fill: $color-blue;
      }
    }
  }
}

.btn-select--filter.bordered {
  border: 1px solid $color-light;
}

//=============================================================================================
// UI * My Purchases & My Sales
//=============================================================================================

#my-purchases,
#my-sales {
  ._accordion {
    ._accordion-content {
      ul._list-content {
        li.flex {
          ._item-content {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            // Frete
            ._frete {
              font-size: 12px;
              position: relative;
              top: -2px;
              margin-left: 6px;

              svg {
                width: 18px;
                height: auto;
                position: relative;
                top: -1px;
                margin-right: 4px;

                path {
                  fill: $color-black;
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }

      ._accordion._inside {
        ._accordion-content {
          ._accordion-content._inside {
            button.small-button {
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

//=============================================================================================
// UI * Buttons
//=============================================================================================

button.small-button {
  border: 0;
  font-size: 12px;
  font-weight: $fw700;
  color: $color-white;
  padding: 0.5rem 1rem;
  background-color: $color-blue;
  -webkit-box-shadow: 0 10px 10px 4px rgba(100, 100, 100, 0.1);
  box-shadow: 0 4px 8px 4px rgba(100, 100, 100, 0.1);
  border: 1px solid rgba(190, 190, 190, 0.1);
  width: $percent-100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;

  @include media-breakpoint-up(xs) {
    max-width: 345px;
  }

  @include media-breakpoint-up(md) {
    max-width: 290px;
  }

  svg {
    width: auto !important;
    height: 14px !important;
    position: relative;
    top: 1px;

    path {
      fill: $color-white;
    }
  }
}

// Button received
button.btn-received {
  border: 0;
  font-size: 13px;
  font-weight: $fw700;
  color: $color-black;
  background-color: $color-white;
  -webkit-box-shadow: 0 10px 10px 4px rgba(100, 100, 100, 0.1);
  box-shadow: 0 4px 8px 4px rgba(100, 100, 100, 0.1);
  border: 1px solid rgba(190, 190, 190, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.65rem;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    background-color: $color-green;
    border-radius: 100px;
    margin-left: 8px;

    svg {
      width: 14px;
      height: auto;

      path {
        fill: $color-white;
      }
    }
  }
}

//=============================================================================================
// UI * Box Print
//=============================================================================================

._box-print {
  max-width: 380px;
  width: $percent-100;
  margin: auto;
  background-color: $color-light;
  border-radius: 4px;
  padding: 0.9rem 0.75rem;
  margin-top: 10px;

  .icon {
    height: 68px;
    width: 68px;
    min-width: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    border-radius: 100px;
    margin: auto;
    margin-bottom: 15px;

    svg {
      height: 36px;
      width: auto;

      path {
        fill: $color-blue;
      }
    }
  }

  .icon.info {
    svg {
      path {
        fill: $color-blue;
      }
    }
  }

  .icon.success {
    svg {
      path {
        fill: $color-green;
      }
    }
  }

  ._text {
    font-family: $font-comfortaa;
    font-size: 13px;
    font-weight: $fw700;
    color: #7d7d81;
    text-align: center;
  }

  button.btn-submit {
    background-color: $color-red;

    &:focus,
    &:active {
      background-color: $color-red;
    }

    svg {
      height: 16px;
      width: auto;
    }
  }
}

.print-preview-iframe {
  margin-top: 20px;
  position: relative;

  iframe {
    width: $percent-100;
    height: auto;
    min-height: 400px;
  }
}

//=============================================================================================
// UI * Tabs Material UI
//=============================================================================================

.MuiTabs-root._material-tabs {
  min-height: 50px;

  .MuiTabs-scroller {
    .MuiTabs-flexContainer {
      background-color: $color-white;
      justify-content: flex-end;
      min-height: 50px;

      button.MuiButtonBase-root.MuiTab-root {
        font-family: $font-comfortaa;
        text-transform: unset;
        font-size: 13px;
        font-weight: $fw700;
        color: $color-black;

        @include media-breakpoint-up(xs) {
          width: 33.333333333333336%;
        }

        @include media-breakpoint-up(md) {
          width: auto;
        }

        .MuiTouchRipple-root {
          display: none !important;
        }
      }

      .MuiButtonBase-root.MuiTab-root.Mui-selected {
        color: $color-blue !important;
      }
    }
    .MuiTabs-indicator {
      background-color: $color-blue;
    }
  }
}

//=============================================================================================
// UI * Single Buttons
//=============================================================================================

.btn.change-address {
  background-color: $color-white;
  border: 1px solid $color-ice;
  color: $color-gray;
  font-size: 11px;
  font-weight: $fw700;
  max-width: inherit;
  width: $percent-100;

  &:focus,
  &:active {
    background-color: $color-white;
  }

  svg {
    position: relative;
    top: 1px;
    path {
      fill: $color-gray;
    }
  }
}

.mark-default-address {
  margin-top: 15px;
  label {
    background-color: #fbfbfb;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    input {
      display: none;
    }

    .text {
      font-size: 12px;
      font-weight: $fw700;
      color: $color-gray-bolder;
    }

    input[type="radio"],
    input[type="checkbox"] {
      display: none;
    }

    .check {
      height: 20px;
      width: 20px;
      min-width: 20px;
      background-color: $color-white;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-ice-bold;

      svg {
        height: 12px;
        width: auto;
        opacity: 0;

        path {
          fill: $color-white;
        }
      }
    }

    input:checked ~ .check {
      background-color: $color-blue;
      border: 1px solid $color-blue;

      svg {
        opacity: 1;
      }
    }
  }
}

//=============================================================================================
// UI * Modal Product Preview
//=============================================================================================
.product-preview {
  padding: 30px 0 15px 0;

  .image {
    max-width: 185px;
    border-radius: 3px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 15px;

    img {
      width: $percent-100;
      height: auto;
    }
  }

  ._list-content {
    margin-bottom: 15px;

    li {
      padding: 0;
      padding-top: 10px;
      padding-bottom: 10px;

      ._item-content {
        ._frete {
          font-size: 12px;
          position: relative;
          top: -2px;

          svg {
            width: 18px;
            height: auto;
            position: relative;
            top: -1px;
            margin-right: 4px;

            path {
              fill: $color-black;
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  .description,
  ._characteristics {
    .title {
      font-size: 13px;
      font-weight: $fw700;
      color: $color-gray-bolder;
      margin-bottom: 10px;
    }
  }

  .description {
    font-weight: $fw700;
    color: $color-gray-bolder;
    font-size: 12px;
    border-bottom: 1px dashed $color-ice;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  ._characteristics {
    ._characteristics-list {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        div {
          font-weight: $fw700;
          font-size: 12px;
        }

        ._key {
          color: $color-gray;
          text-transform: capitalize;
        }
        ._value {
          color: $color-gray-bolder;
          text-transform: lowercase;
        }
      }
    }
  }
}

.loading-product {
  border: 1px solid #f7f7f7;
  border-radius: 2px;
}

//=============================================================================================
// UI * Modal Product Preview
//=============================================================================================
.cookie-alert {
  background-color: $color-blue;
  padding: 0.9375rem 0;

  ._med-container {
    ._cols {
      display: flex;
      align-items: center;

      @include media-breakpoint-up(xs) {
        flex-direction: column;
        justify-content: center;
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
      }

      ._col {
        @include media-breakpoint-up(xs) {
          width: $percent-100;
          text-align: center;
        }

        @include media-breakpoint-up(md) {
          width: auto;
          text-align: left;
        }

        p {
          color: $color-white;
          font-size: 12px;
          line-height: 1.8;
        }

        button {
          // max-width: 136px;

          @include media-breakpoint-up(xs) {
            max-width: 380px;
            margin-top: 10px;
          }

          @include media-breakpoint-up(md) {
            max-width: 136px;
            margin-top: 0;
          }
        }

        &:first-child {
          padding-right: 15px;
        }
      }
    }
  }
}
