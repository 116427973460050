#view_access {
  height: 100%;

  ._wrap-access-screen {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0 200px 0;

    .access-screen {
      max-width: 420px;
      width: 100%;
      height: auto;
      padding: 60px 30px;
      border-radius: 3px;
      text-align: center;

      ._access-screen-title {
        text-align: center;
        color: $color-black;
        font-weight: $fw700;

        @include media-breakpoint-up(xs) {
          font-size: 18px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 30px;
        }

        .heading {
          display: block;
          color: $color-gray;
          margin-top: 5px;
          font-weight: $fw400;

          @include media-breakpoint-up(xs) {
            font-size: 13px;
          }

          @include media-breakpoint-up(lg) {
            font-size: 14px;
          }
        }
      }

      button {
        margin: {
          top: 20px;
          bottom: 15px;
        }
      }

      ._have-account {
        font-size: 13px;
      }
    }
  }
}

#view_login,
#view_create-account {
  ._wrap-auth-screen {
    display: flex;
    align-items: center;
    justify-content: center;

    .auth-screen {
      max-width: 450px;
      width: 100%;
      padding: 50px 30px;
      background-color: $color-white;
      border-radius: 3px;

      ._auth-screen-title {
        margin-bottom: 30px;
        justify-content: center;
      }

      ._or {
        padding: 5px 0;

        .divider {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: center;
          width: 100%;
          margin: 30px 0;

          &::before,
          &::after {
            display: block;
            content: "";
            height: 1px;
            width: 100%;
            background-color: $color-ice;
          }

          .text {
            padding: 0 10px;
          }
        }
      }

      ._wrap-form {
        form {
          ._checkbox.form-check {
            margin-top: 15px;
          }

          .form-group-button {
            margin-top: 15px;
          }

          ._wrap-recovery {
            display: flex;
            align-items: center;
            justify-content: space-between;

            ._checkbox.form-check {
              .form-check-label {
                ._alt-text {
                  @include media-breakpoint-up(xs) {
                    font-size: 11px;
                  }

                  @include media-breakpoint-up(md) {
                    font-size: 12px;
                  }
                }
              }
            }

            ._link-recovery-password {
              font-weight: $fw700;
              color: $color-nude;
              position: relative;
              top: 6px;
              cursor: pointer;

              @include media-breakpoint-up(xs) {
                font-size: 11px;
              }

              @include media-breakpoint-up(md) {
                font-size: 12px;
              }
            }

            ._container-recoverry-password {
              display: flex;
              flex-direction: column;
            }

            ._link-frist-access {
              font-weight: $fw700;
              color: $color-nude;
              position: relative;
              top: 6px;
              cursor: pointer;
              margin-top: 5px;

              @include media-breakpoint-up(xs) {
                font-size: 11px;
              }

              @include media-breakpoint-up(md) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

._wrap-auth-screen {
  @include media-breakpoint-up(sm) {
    padding: 100px 0 200px 0;
  }

  .auth-screen {
    @include media-breakpoint-up(sm) {
      width: $percent-100;
      border-color: $color-ice;
      border-width: 1px;
      border-style: solid;
      -webkit-box-shadow: 0 4px 14px 4px rgba(180, 180, 180, 0.1);
      box-shadow: 0 4px 14px 4px rgba(180, 180, 180, 0.1);
    }
  }
}

._have-account {
  margin-top: 25px;
}
