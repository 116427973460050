
// Overwrite Containers Sizes

._fluid-container {
    width: $flu-container;
}

._max-container {
    max-width: $max-container;
}

._med-container {
    max-width: $med-container;
}

._min-container {
    max-width: $min-container;
}

._mob-container {
    max-width: $mob-container;
}

._container-1440px,
._max-container,
._med-container,
._min-container,
._mob-container {
    @include push--auto;
    @include padding-lt-rt;
}

// Padding, Margins Containers

.w-40 {
    padding: 40px 0;
}

.w-60 {
    padding: 60px 0;

    @media (max-width: 768px) {
        padding: 40px 0;
    }
}

// Font Family

._font._comfortaa {
    font-family: $font-comfortaa;
}
._font._poppins {
    font-family: $font-poppins;
}

// Font Weight

._fw700 {
    font-weight: $fw700;
}
._fw500 {
    font-weight: $fw500;
}
._fw400 {
    font-weight: $fw400;
}
._fw300 {
    font-weight: $fw300;
}
._fw200 {
    font-weight: $fw200;
}

// Font Helper

._transf._upper {
    text-transform: uppercase;
}
._transf._none {
    text-transform: none !important;
}

// Background Colors

._bg-color._white {
    background-color: $color-white;
}
._bg-color._ice {
    background-color: $color-ice;
}
._bg-color._nude {
    background-color: $color-nude;
}
._bg-color._orange {
    background-color: $color-orange;
}
._bg-color._brown-lt {
    background-color: $color-brown-lt;
}
._bg-color._brown {
    background-color: $color-brown;
}
._bg-color._violet {
    background-color: $color-violet;
}
._bg-color._concrete {
    background-color: $color-concrete;
}
._bg-color._alto {
    background-color: $color-alto;
}
._bg-color._yellow {
    background-color: $color-yellow;
}
._bg-color._shock {
    background-color: $color-shock;
}
._bg-color._silver {
    background-color: $color-silver;
}
._bg-color._red {
    background-color: $color-red;
}
._bg-color._gray {
    background-color: $color-gray;
}
._bg-color._green {
    background-color: $color-green;
}
._bg-color._blue {
    background-color: $color-blue;
}
._bg-color._blue-bolder {
    background-color: $color-blue-bolder;
}
._bg-color._blue-light {
    background-color: $color-blue-light;
}

// Font Colors

._color._white {
    color: $color-white;
}
._color._orange {
    color: $color-orange;
}
._color._nude {
    color: $color-nude;
}
._color._black {
    color: $color-black;
}
._color._violet {
    color: $color-violet;
}
._color._brown-lt {
    color: $color-brown-lt;
}
._color._concrete {
    color: $color-concrete;
}
._color._alto {
    color: $color-alto;
}
._color._yellow {
    color: $color-yellow;
}
._color._shock {
    color: $color-shock;
}
._color._silver {
    color: $color-silver;
}
._color._red {
    color: $color-red;
}
._color._gray {
    color: $color-gray;
}
._color._gray-bolder {
    color: $color-gray-bolder;
}
._color._green {
    color: $color-green;
}
._color._blue {
    color: $color-blue;
}
._color._blue-bolder {
    color: $color-blue-bolder;
}
