#view_home {
    background-color: $color-white;

    // * Quick Links
    .quick-links {
        background-color: $color-ice;

        @include media-breakpoint-up(xs) {
            padding: 12px 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 20px 0;
        }

        ._med-container {
            overflow: hidden;

            .wrap-links {
                display: flex;
                align-items: flex-start;
                width: $percent-100;
                overflow-y: auto;

                @include media-breakpoint-up(xs) {
                    justify-content: flex-start;
                }

                @include media-breakpoint-up(sm) {
                    justify-content: center;
                }

                .quick-link {
                    text-align: center;
                    cursor: pointer;

                    @include media-breakpoint-up(xs) {
                        margin-left: 20px;
                    }

                    @include media-breakpoint-up(sm) {
                        margin-left: 30px;
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    .image {
                        border-radius: 100%;
                        overflow: hidden;
                        border: 1px solid $color-light;
                        margin: 0 auto;

                        @include media-breakpoint-up(xs) {
                            height: 60px;
                            width: 60px;
                        }

                        @include media-breakpoint-up(sm) {
                            height: 80px;
                            width: 80px;
                        }

                        @include media-breakpoint-up(lg) {
                            height: 100px;
                            width: 100px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border: 0;
                            max-width: 100%;
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }

                    .title {
                        font-weight: $fw700;
                        color: $color-black;
                        text-align: center;
                        margin-top: 6px;

                        @include media-breakpoint-up(xs) {
                            font-size: 11px;
                        }

                        @include media-breakpoint-up(lg) {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    #hero-slider {
        position: relative;
        //        overflow: hidden;

        @include media-breakpoint-up(xs) {
            padding: {
                top: 0px;
                bottom: 15px;
            }
        }
        @include media-breakpoint-up(md) {
            padding: {
                // top: 25px;
            }
        }

        @include media-breakpoint-up(sm) {
            @include padding-lt-rt;
            // padding: 0;
        }

        @include media-breakpoint-up(lg) {
            @include padding-lt-rt;
        }

        .slider-container {
            padding-bottom: 30px;
            @include push--auto;
            max-width: 1340px;

            @include media-breakpoint-up(lg) {
                // @include padding-lt-rt;
            }

            a.slider-item {
                picture {
                    source,
                    img {
                        width: 100%;
                    }
                }
            }

            .swiper-pagination {
                bottom: -30px;
            }

            &::after {
                display: block;
                content: "";
                background-image: url("#{$path-image}/after-mobile-banner.png");
                background-repeat: repeat-x;
                background-position: left;
                background-size: contain;
                height: 8px;
                width: 100%;
                margin: 0 auto;
                margin-top: -5px;

                @include media-breakpoint-up(xs) {
                    width: 86.89%;
                }

                @include media-breakpoint-up(sm) {
                    width: 90.2%;
                }

                @include media-breakpoint-up(md) {
                    width: 90.55%;
                }
            }

            .slick-dots {
                bottom: 0;
            }
        }

        .slide-action {
            text-align: center;
            margin-top: 10px;
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    ._med-container {
        ._wrap-top-categories {
            margin-top: 30px;

            // @include media-breakpoint-up(xs) {
            //     display: none;
            // };

            // @include media-breakpoint-up(lg) {
            //     display: block;
            // }

            .headline {
                margin-bottom: 0;
            }
        }

        ._wrap-recently-added {
            @include media-breakpoint-up(xs) {
                padding: 40px 0;
            }

            @include media-breakpoint-up(lg) {
                padding: 40px 0;
            }

            ._recently-added {
                margin-top: 15px;
            }

            .slick-slider._recently-added {
                .slick-arrow.slick-prev,
                .slick-arrow.slick-next {
                    background-color: rgba(180, 180, 180, 0.6);
                }

                .slick-arrow.slick-prev {
                    margin-left: 0;
                }

                .slick-arrow.slick-next {
                    margin-right: 0;
                }
            }
        }

        ._wrap-official-stores {
            @include media-breakpoint-up(xs) {
                padding: 20px 0 40px 0;
            }

            @include media-breakpoint-up(lg) {
                padding: 0 0 40px 0;
            }

            .headline {
                margin-bottom: 20px;
            }

            .slick-slider._official-stores {
                .slick-arrow.slick-prev,
                .slick-arrow.slick-next {
                    background-color: rgba(180, 180, 180, 0.6);
                }

                .slick-arrow.slick-prev {
                    margin-left: 0;
                }

                .slick-arrow.slick-next {
                    margin-right: 0;
                }
            }

            ._ui-card.official-stores-slide {
                max-width: 290px;
                width: 100%;
                height: 320px;
                border: 1px solid #f7f7f7;
                border-radius: 2px;
                position: relative;

                ._ui-item-wrapper {
                    .official-stores-slide-background {
                        height: 130px;
                        overflow: hidden;
                        border-top-left-radius: 2px;
                        border-top-right-radius: 2px;

                        img {
                            display: inline-block;
                            width: auto;
                            height: 100%;
                            margin: 0 auto;
                        }
                    }

                    .official-stores-slide-logo {
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-start;
                        margin-top: -40px;
                        @include padding-lt-rt;

                        .logo {
                            max-width: 120px;
                            height: 80px;
                            width: 100%;
                            border: 1px solid #f1f1f1;
                            border-radius: 6px;
                            background-color: $color-white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            img {
                                height: auto;
                                width: 100px;
                            }
                        }

                        .title-wrapper {
                            display: block;
                            margin-left: 15px;
                            position: relative;
                            top: -5px;
                        }
                    }

                    .official-stores-slide-items {
                        margin-top: 30px;
                        @include padding-lt-rt;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        a {
                            display: block;
                            border: 1px solid #f1f1f1;
                            width: 80px;
                            height: 54px;
                            position: relative;
                            overflow: hidden;

                            img {
                                height: 100%;
                                width: auto;
                                margin: 0 auto;
                            }

                            .points-tag {
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;
                                background-color: $color-white;
                                font-size: 10px;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                padding: 1px 6px;
                                border: 0;
                                border-top: 1px solid #f1f1f1;
                                border-right: 1px solid #f1f1f1;
                                border-top-right-radius: 6px;
                                color: $color-black;
                                font-weight: $fw700;

                                .points-tag-symbol {
                                    font-size: 7px;
                                    margin-left: 2px;
                                    position: relative;
                                    top: 1px;
                                }
                            }
                        }
                    }

                    .official-stores-action {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        bottom: 15px;
                        left: 0;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                        max-width: 100px;
                        text-align: center;
                        color: $color-black;
                        font-size: 13px;
                        opacity: 0.8;

                        svg {
                            width: 7px;
                            height: 13px;
                            margin-left: 5px;
                            position: relative;
                            top: -1px;
                        }
                    }
                }
            }
        }
    }

    .highlight-boxes {
        .no-container,
        ._med-container {
            ._xs522 {
                .highlight-box {
                    margin-top: 30px;
                }
                
                .highlight-box.smartphone {
                    &:first-child {
                        margin-top: 0 !important;
                    }
                }

                .highlight-box.desktop {
                    &:first-child {
                        margin-top: 0 !important;
                    }
                }
            }
        }
    }
}

.Knowmore-box {
    background-color: $color-ice;
    .container {
        max-width: 1090px;

        ._be-scamber-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 60px 0;

            .wrapper-headline {
                margin-bottom: 50px;

                ._be-scamber-title {
                    margin-bottom: 10px;
                    text-align: center;
                    color: $color-blue;

                    @include media-breakpoint-up(xs) {
                        font-size: 24px;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 36px;
                    }
                }

                ._be-scamber-subtitle {
                    text-align: center;

                    @include media-breakpoint-up(xs) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 22px;
                    }
                }
            }

            ._be-scamber-knowmore-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: $percent-100;

                @include media-breakpoint-up(xs) {
                    flex-direction: column;
                }

                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                }
            }

            ._knowmore-container {
                display: flex;
                align-items: center;
                text-align: center;
                max-width: 340px;
                width: $percent-100;

                @include media-breakpoint-up(xs) {
                    justify-content: space-between;
                    flex-direction: column;
                }

                @include media-breakpoint-up(lg) {
                    justify-content: center;
                    margin-left: 0;

                    &:first-child {
                        margin-left: 0;
                    }
                    flex-direction: row;
                }

                &:nth-child(2) {
                    @include media-breakpoint-up(xs) {
                        border-top: 1px solid $color-ice-bold;
                        border-bottom: 1px solid $color-ice-bold;
                        margin: 40px 0;
                        padding: 40px 0;
                    }

                    @include media-breakpoint-up(lg) {
                        margin-top: 0;
                        margin-bottom: 0;
                        margin-left: 0;
                        margin-right: 0;
                        padding: 0;
                        border: 0;
                    }
                }

                .wrapper {
                    ._knowmore-title {
                        color: $color-blue;

                        @include media-breakpoint-up(xs) {
                            font-size: 20px;
                        }

                        @include media-breakpoint-up(lg) {
                            font-size: 24px;
                        }
                    }

                    ._knowmore-text {
                        font-weight: $fw700;
                        padding: 0 15px;
                        color: $color-blue;

                        @include media-breakpoint-up(xs) {
                            font-size: 0.8125rem;
                            margin: 10px 0 30px 0;
                        }

                        @include media-breakpoint-up(lg) {
                            min-height: 128px;
                            font-size: 0.875rem;
                            margin: 20px 0 15px 0;
                        }
                    }

                    button.btn {
                        background-color: $color-blue;
                        padding: 0.669rem 0.75rem;
                        max-width: 180px;

                        a {
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }
}

#top-categories {
    display: flex;
    padding-top: 15px;
    position: relative;

    .headline {
        margin-bottom: 20px;
    }

    ._grid {
        @include media-breakpoint-up(xs) {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            display: block;
        }

        ._grid-col {
            display: block;
            overflow: hidden;
            text-align: center;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            ._grid-col-head {
                text-align: left;
                position: absolute;
                top: 0;
                left: 0;

                padding: {
                    top: 20px;
                    left: 20px;
                }

                span {
                    text-transform: lowercase;
                }
            }

            img {
                margin: 0 auto;
                width: auto;
            }
        }

        &:nth-child(1) {
            ._grid-col {
                ._grid-col-head {
                    span {
                        font-size: 16px;
                    }

                    h1 {
                        font-size: 30px;
                    }
                }

                margin-right: 15px;

                @include media-breakpoint-up(lg) {
                    width: 424px;
                    height: 405px;
                }

                @include media-breakpoint-up(xl) {
                    width: 450px;
                    height: 445px;
                }
            }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
            ._grid-col {
                ._grid-col-head {
                    span {
                        font-size: 14px;
                    }

                    h1 {
                        font-size: 20px;
                    }
                }

                @include media-breakpoint-up(lg) {
                    width: 270px;
                    height: 195px;
                }

                @include media-breakpoint-up(xl) {
                    width: 290px;
                    height: 215px;
                }
            }
        }

        &:nth-child(2),
        &:nth-child(3) {
            align-self: flex-start;
        }

        &:nth-child(3) {
            margin-left: 15px;
        }

        &:nth-child(4),
        &:nth-child(5) {
            align-self: flex-end;
            position: absolute;
            right: 0;
        }

        &:nth-child(4) {
            @include media-breakpoint-up(lg) {
                right: 285px;
            }

            @include media-breakpoint-up(xl) {
                right: 305px;
            }
        }

        // Image #1
        &:nth-child(1) {
            ._grid-col {
                align-items: flex-end;

                // img {
                //     width: 84%;
                // }
            }
        }

        // Image #2
        &:nth-child(2) {
            ._grid-col {
                align-items: flex-end;

                // img {
                //     width: 48%;
                // }
            }
        }

        // Image #3
        &:nth-child(3) {
            ._grid-col {
                align-items: flex-end;

                // img {
                //     width: 28%;
                // }
            }
        }

        // Image #4
        &:nth-child(4) {
            ._grid-col {
                align-items: flex-end;

                // img {
                //     width: 35%;
                //     position: relative;
                //     top: 50px;
                // }
            }
        }

        // Image #5
        &:nth-child(5) {
            ._grid-col {
                align-items: center;
                background-color: #ffd126;

                // img {
                //     width: 100%;
                // }
            }
        }
    }

    // * Mobile Categories Only
    #mobile-categories {
        width: $percent-100;

        @include media-breakpoint-up(xs) {
            display: block;
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }

        .category-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: $percent-100;
            height: 80px;
            border-radius: 6px;
            padding: 0 15px;
            margin-bottom: 10px;

            &:last-child {
                background-color: #ffd126;
                margin-bottom: 0;
            }

            .title-subtitle {
                h1,
                h2 {
                    color: $color-white;
                    font-weight: $fw700;
                }

                h1 {
                    font-size: 1.125rem;
                    margin-top: 0.25rem;
                }

                h2 {
                    font-size: 0.5625rem;
                }
            }

            .image {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                height: $percent-100;
                max-width: 150px;
                overflow: hidden;

                img {
                    width: $percent-100;
                }
            }

            &:nth-child(1) {
                .image {
                    align-items: center;

                    img {
                        max-width: 82px;
                    }
                }
            }

            &:nth-child(2) {
                .image {
                    img {
                        max-width: 70px;
                    }
                }
            }

            &:nth-child(3) {
                .image {
                    img {
                        max-width: 40px;
                    }
                }
            }

            &:nth-child(4) {
                .image {
                    align-items: center;
                    img {
                        max-width: 56px;
                    }
                }
            }
        }
    }
}
